
/* @flow */

import type { Mode } from "state/view-mode";

import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import SearchMenu from "components/AppHeader/SearchMenu";
import ChevronIcon from "icons/chevron-mini.svg";
import SearchIcon from "icons/search.svg";
import Categories from "components/AppHeader/Large/Categories";

import { MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  mode: Mode,
  setMode: Mode => void,
};

const Shop = ({ mode, setMode }: Props) => {
  const toggleCategories = () => setMode(mode === MODE.CATEGORIES ? MODE.NORMAL : MODE.CATEGORIES);
  const { categories, routes } = useContext(StoreInfoContext);

  return (
    <>
      {routes.allProductsView && routes.allProductsView.toggle !== undefined &&
      routes.allProductsView.toggle && routes.allProductsView.title !== undefined &&
      routes.allProductsView.title && routes.allProductsView.url !== undefined &&
      routes.allProductsView.url && (
        <NavLink
          to={routes.allProductsView.url}
          activeClassName={styles.active}
          className={cn("awardit-subMenuShopItem", styles.item)}
        >
          {routes.allProductsView?.title}
        </NavLink>
      )}

      <span
        className={
          cn(
            "awardit-subMenuShopItem",
            styles.item,
            styles.item__categories,
            { [styles.active]: mode === MODE.CATEGORIES }
          )
        }
        onClick={toggleCategories}
      >
        {(routes.categoriesView && routes.categoriesView.title) ?? ""}
        <ChevronIcon className={styles.chevron} />

        {mode === MODE.CATEGORIES &&
        <nav className={styles.categories}>
          <Categories categories={categories} />
        </nav>
        }

      </span>

      {routes.brandsView && routes.brandsView.url && routes.brandsView.title &&
        <Link
          className={
            cn(
              "awardit-subMenuShopItem",
              styles.item,
              styles.item__categories,
              styles.brands
            )
          }
          to={{
            pathname: routes.brandsView?.url,
          }}
        >
          {routes.brandsView?.title}
        </Link>
      }
      <span
        className={
          cn(
            "awardit-subMenuShopItem",
            "awardit-subMenuSearchItem",
            styles.item,
            styles.item__search,
            { [styles.active]: mode === MODE.SEARCH }
          )
        }
        onClick={() => setMode(MODE.SEARCH)}
      >

        {mode !== MODE.SEARCH &&
        <div>
          <SearchIcon />
          {(routes.searchView && routes.searchView.title) ?? ""}
        </div>
        }
        <nav className={cn("awardit-subMenuSearch", styles.search)} style={{ width: mode === MODE.SEARCH ? "280px" : "0px" }}>
          <SearchMenu autoFocus />
        </nav>
      </span>
    </>
  );
};

export default Shop;
